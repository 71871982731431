import { loadRecaptcha } from './load-recaptcha';

export async function getRecaptchaToken(
	action: string,
	key = '6LfsP6IUAAAAANY4ORpLRV94k5tP4PFl0y3tPtF3'
) {
	await loadRecaptcha();

	return await (window as any).grecaptcha.execute(key, { action });
}
