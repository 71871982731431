export default function setTabIndexes(
	focusArea: Node,
	tabbable = true,
	selector = 'a, button, input, select, textfield, textarea, [tabindex]'
) {
	if (focusArea instanceof HTMLElement) {
		const focusableElements = focusArea.querySelectorAll(selector);

		focusableElements.forEach((focusableElement) => {
			if (tabbable) {
				// Make the element foncusable.

				// If the element had a tabindex before, restore the old tabindex.
				if (focusableElement.hasAttribute('data-tabindex')) {
					focusableElement.setAttribute(
						'tabindex',
						focusableElement.getAttribute('data-tabindex')!
					);

					// Remove the reference to the old tab index.
					focusableElement.removeAttribute('data-tabindex');
				} else {
					focusableElement.setAttribute('tabindex', '0');
				}
			} else {
				// Make element not focusable.

				// If the element had a tabindex before, save the old tabindex, unless it previously had a old taxindex.
				if (
					focusableElement.hasAttribute('tabindex') &&
					!focusableElement.hasAttribute('data-tabindex')
				) {
					focusableElement.setAttribute(
						'data-tabindex',
						focusableElement.getAttribute('tabindex')!
					);
				}

				// Set the element as non-focusable.
				focusableElement.setAttribute('tabindex', '-1');
			}
		});
	}
}
