import { eventToDataLayer } from './analytics';
import { getRecaptchaToken } from './get-recaptcha-token';

function contactFormInit() {
	const form = document.getElementById('contact-form') as HTMLFormElement;

	if (!form) {
		return;
	}

	const textarea = form.querySelector<HTMLTextAreaElement>('#contact-message');

	if (textarea) {
		textarea.oninvalid = () => {
			textarea.setCustomValidity('');

			if (!textarea.validity.valid) {
				textarea.setCustomValidity(
					`Please provide more details about your project. We'd love to learn more about what you are trying to do.`
				);
			}
		};

		textarea.oninput = () => {
			textarea.setCustomValidity('');
		};
	}

	form.addEventListener('submit', async (e) => {
		e.preventDefault();

		const fullName = (document.getElementById('contact-name') as HTMLInputElement).value;

		const firstName = fullName
			.split(' ')
			.slice(0, -1)
			.join(' ');
		const lastName = fullName
			.split(' ')
			.slice(-1)
			.join(' ');
		const email = (document.getElementById('contact-email') as HTMLInputElement).value;
		const message = (document.getElementById('contact-message') as HTMLInputElement).value;

		try {
			await sendEmail(`${firstName} ${lastName}`, email, message);
			await sendCRM(firstName, lastName, email, message);

			form.reset();

			eventToDataLayer('form', 'contact-success', '');
		} catch (ex) {
			console.log('Error in sending form.', ex);
		}
	});
}

/**
 * Sends an email using the provided parameters.
 * @param {string} name Full name of the contact
 * @param {string} email Email of the contact.
 * @param {string} message The contents of the email.
 */
async function sendEmail(name: string, email: string, message: string) {
	const token = await getRecaptchaToken('cwg_contact');
	const body = JSON.stringify({
		form: { Name: name, Email: email, Message: message, Token: token },
		settingsId: '{EA12BE30-5A3E-45FD-9A3D-F601814C2FB3}'
	});

	const response = await fetch('https://www.habaneroconsulting.com/_services/contact/send', {
		body,
		cache: 'no-cache',
		headers: {
			'Content-Type': 'application/json'
		},
		method: 'POST'
	});

	const respText =
		(response[0] && `${response[0].status}|${response[0].reject_reason}`) || '(not set)'; // set default
	eventToDataLayer('form', 'submit-response', respText);

	if (response.ok) {
		// defaultContent references the container with blank input fields / not submitted yet.
		const defaultContent = document.querySelector('.contact__not-submitted');
		const submittedContent = document.querySelector('.contact__submitted');

		if (!defaultContent || !submittedContent) {
			return;
		}

		defaultContent.classList.toggle('u-display-none');
		submittedContent.classList.toggle('u-display-none');
	}
}

/**
 * Sends contact information to CRM.
 * @param {string} firstname First name of contact.
 * @param {string} lastname Last name of contact.
 * @param {string} email Email of the contact.
 * @param {string} message The contents of the email.
 */
async function sendCRM(firstname: string, lastname: string, email: string, message: string) {
	const token1 = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9';
	const token2 =
		'eyJpc3MiOiJOV0MiLCJ3b3JrZmxvd0lkIjoiN2Q2NDVhNjMtYTY3MS00MDI1LWEzZGMtMTFkOTc3OTM5MTcyIiwidGVuYW50SWQiOiIxMDc1NjNhZS03ZGFjLTQ2NTUtYTViYS01OWVmMWM1YTEwMzUiLCJpYXQiOjE0OTAyMTk1MzB9';
	const token3 = 'CFSEmNYrbjqSLZx6blcfndaNPLSSqeqpbKqKGOchHK8';

	const data = {
		startData: {
			se_txt_description1: message,
			se_txt_company_email1: email,
			se_txt_last_name1: lastname,
			se_txt_first_name1: firstname
		}
	};

	await fetch(
		`https://habaneroconsulting-1.workflowcloud.com/api/v1/workflow/published/7d645a63-a671-4025-a3dc-11d977939172/instances?token=${token1}.${token2}.${token3}`,
		{
			body: JSON.stringify(data),
			headers: {
				'content-type': 'application/json'
			},
			method: 'POST'
		}
	);
}

contactFormInit();
