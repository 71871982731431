export async function loadRecaptcha() {
	return await loadScript(
		'https://www.google.com/recaptcha/api.js?render=6LfsP6IUAAAAANY4ORpLRV94k5tP4PFl0y3tPtF3'
	);
}

export function loadScript(src: string) {
	return new Promise((resolve, reject) => {
		const existingScript = document.querySelector(`script[src="${src}"]`);

		if (existingScript) {
			resolve();
		} else {
			const script = document.createElement('script');

			script.src = src;
			script.async = true;
			script.defer = true;
			document.body.appendChild(script);

			script.onload = () => {
				(window as any).grecaptcha.ready(() => {
					resolve();
				});
			};

			script.onerror = () => {
				reject();
			};
		}
	});
}
