import setTabIndexes from './set-tab-indexes';

const CLASS_NAME_DISPLAY_NONE = 'u-display-none';
const CLASS_NAME_NO_SCROLL = 'body--no-scroll';
const CLASS_NAME_MOBILE_NAV_OPEN = 'mobile-nav--open';
const CLASS_NAME_WRAPPER_MOBILE_NAV_OPEN = 'body__wrapper--mobile-nav-open';

let isOpen = false;

/**
 * Toggle Nav on click of hamburger navButton and of mobile-nav links.
 * As window resizes, check if we need to toggle Nav.
 */
function initMobileNav() {
	// When the user clicks on the "hamburger" icon, mobile navigation is toggled
	const navButton = document.querySelector('.header__mobile-nav-button');

	if (!navButton) {
		return;
	}

	navButton.addEventListener('click', () => toggleNav());

	const mobileLinks = document.querySelectorAll('.js-mobile-nav-link');

	mobileLinks.forEach((link) => {
		link.addEventListener('click', () => toggleNav());
	});

	window.addEventListener('resize', checkNeedToHide);
}

/**
 * If window reaches tablet width, toggle Nav
 */
function checkNeedToHide() {
	const mq = window.matchMedia('(min-width: 768px)');

	if (!mq.matches) {
		return;
	}

	toggleNav(false);
}

/**
 * Add/Remove active Nav classes if Nav should be toggled open or closed
 * If the Nav is open, remove tabbing capability from body & add it for the Nav (and opposite).
 * @param shouldOpen Indicates whether Nav should be toggled open or not
 */
function toggleNav(shouldOpen = !isOpen) {
	isOpen = shouldOpen;

	const body = document.querySelector('.body');
	const bodyWrapper = document.querySelector('.body__wrapper');
	const mobileNav = document.querySelector('.mobile-nav');
	const primaryNav = document.querySelector<HTMLElement>('.header');

	if (!body || !bodyWrapper || !mobileNav || !primaryNav) {
		return;
	}

	if (shouldOpen) {
		body.classList.add(CLASS_NAME_NO_SCROLL);
		bodyWrapper.classList.add(CLASS_NAME_WRAPPER_MOBILE_NAV_OPEN);

		// hide primaryNav (-4rem accounts for nav shadow)

		primaryNav.style.top = '-4rem';

		// The mobile nav slides in from right / & opposite
		mobileNav.classList.add(CLASS_NAME_MOBILE_NAV_OPEN);
	} else {
		body.classList.remove(CLASS_NAME_NO_SCROLL);
		bodyWrapper.classList.remove(CLASS_NAME_WRAPPER_MOBILE_NAV_OPEN);

		// show primaryNav
		primaryNav.removeAttribute('style');

		// The mobile nav slides in from right / & opposite
		mobileNav.classList.remove(CLASS_NAME_MOBILE_NAV_OPEN);
	}

	setTabIndexes(bodyWrapper, !isOpen);
	setTabIndexes(mobileNav, isOpen);
}

initMobileNav();
