import { on } from 'delegated-events';

/**
 * Init - initializes all gtm and analytic events
 */
function main() {
	trackNavigationEvents();
	trackFeatureTabSwitcherEvents();
	trackGenericClickEvents();
	trackExitLinkEvents();
	trackMailtoEvents();
	trackMiscEvents();
	trackFormEvents();
}

/**
 * Helper function to push event to dataLayer
 */
export function eventToDataLayer(category: string, action: string, label: string) {
	if (typeof (window as any).dataLayer !== 'undefined') {
		const event = {
			event: 'GAevent',
			eventCategory: category,
			eventAction: action,
			eventLabel: label
		};

		(window as any).dataLayer.push(event);
	}
}

/**
 * Tracks generic click events based on css class, and data attributes
 */
function trackGenericClickEvents() {
	on('click', '[ga-category]', ({ currentTarget }: MouseEvent) => {
		if (!(currentTarget instanceof HTMLElement)) {
			return;
		}

		const category = currentTarget.getAttribute('ga-category') || '';
		const action = currentTarget.getAttribute('ga-action') || '';
		const label = currentTarget.getAttribute('ga-label') || '';

		eventToDataLayer(category, action, label);
	});
}

/**
 * Tracks exit link events (user's linking out of the site)
 */
function trackExitLinkEvents() {
	on('click', 'a', ({ currentTarget }: MouseEvent) => {
		if (!(currentTarget instanceof HTMLAnchorElement)) {
			return;
		}
		const url = currentTarget.href;

		if (currentTarget.host && currentTarget.host !== window.location.host) {
			eventToDataLayer('exit', 'external-link-click', url);
		}
	});
}

/**
 * Tracks all mailto click events (triggering a new mail message)
 */
function trackMailtoEvents() {
	on('click', 'a[href*="mailto:"]', ({ currentTarget }: MouseEvent) => {
		if (!(currentTarget instanceof HTMLAnchorElement)) {
			return;
		}

		const label = currentTarget.href.substring(7);

		eventToDataLayer('contact', 'email', label.toLowerCase());
	});
}

/**
 * Tracks all tab-switcher click events
 */
function trackFeatureTabSwitcherEvents() {
	on('click', 'a.tabswitcher__anchor', ({ currentTarget }: MouseEvent) => {
		if (!(currentTarget instanceof HTMLAnchorElement)) {
			return;
		}

		const label = currentTarget.innerText.trim() || '(not set)';
		const section = currentTarget.closest('section[id]');
		const locale = (section ? `${section.id}|` : '(no section)|').toLowerCase();

		eventToDataLayer('tabs', 'select', locale + label.toLowerCase());
	});
}

/**
 * Tracks all navigation events
 */
function trackNavigationEvents() {
	on('click', 'a.primary-nav__anchor', ({ currentTarget }: MouseEvent) => {
		if (!(currentTarget instanceof HTMLAnchorElement)) {
			return;
		}
		const label = currentTarget.innerText.trim() || '(not set)';

		eventToDataLayer('navigation|header', 'navigate', label.toLowerCase());
	});

	/* Mobile-specific (non-desktop) navigation tracking */
	on('click', 'a.mobile-nav__anchor', ({ currentTarget }: MouseEvent) => {
		if (!(currentTarget instanceof HTMLAnchorElement)) {
			return;
		}

		const label = currentTarget.innerText.trim() || '(not set)';

		eventToDataLayer('navigation|menu', 'navigate', label.toLowerCase());
	});
}

/**
 * Tracks Form events
 */
function trackFormEvents() {
	on('click', '#newsletter .button--action', ({ currentTarget }: MouseEvent) => {
		if (!(currentTarget instanceof HTMLElement)) {
			return;
		}

		eventToDataLayer('form', 'newsletter-submit-attempt', '');
	});

	on('click', '#contact .button--action', ({ currentTarget }: MouseEvent) => {
		if (!(currentTarget instanceof HTMLElement)) {
			return;
		}

		eventToDataLayer('form', 'contact-submit-attempt', '');
	});
}

/**
 * Track misc events
 */
function trackMiscEvents() {
	// Track home logo clicks
	on('click', '.header__logo', ({ currentTarget }: MouseEvent) => {
		if (!(currentTarget instanceof HTMLAnchorElement)) {
			return;
		}

		eventToDataLayer('logo', 'click', '');
	});

	// Track CTA button clicks -- Nav ribbon
	on('click', '[href="#contact"]', ({ currentTarget }: MouseEvent) => {
		if (!(currentTarget instanceof HTMLElement)) {
			return;
		}

		const text = currentTarget.innerText.trim() || '(not set)';

		eventToDataLayer('call-to-action', 'click', `cta:${text.toLowerCase()}`);
	});
}

main();
