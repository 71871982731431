function initHeroBanner() {
	const pictures = document.querySelectorAll('.hero-panel__background-picture');
	const picture = pictures[Math.floor(Math.random() * pictures.length)];

	if (picture) {
		picture.classList.remove('u-display-none');
	}
}

initHeroBanner();
