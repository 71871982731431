import { loadRecaptcha } from './load-recaptcha';
import { eventToDataLayer } from './analytics';
import { getRecaptchaToken } from './get-recaptcha-token';

const CLASS_NAME_NEWSLETTER_INPUT = 'newsletter__input';

function newsletterFormInit() {
	const form = document.getElementById('newsletter-form') as HTMLFormElement;

	if (!form) {
		return;
	}

	const inputs = form.querySelectorAll(`.${CLASS_NAME_NEWSLETTER_INPUT}[name]`);

	inputs.forEach((input) => input.addEventListener('focus', () => loadRecaptcha()));

	form.addEventListener('submit', async (e) => {
		e.preventDefault();

		try {
			await loadRecaptcha();

			(window as any).grecaptcha.ready(async () => {
				const token = await getRecaptchaToken('cwg_newsletter');

				if (!token) {
					return;
				}

				await submitForm(token);

				eventToDataLayer('form', 'newsletter-success', '');

				const defaultContent = document.querySelector('.newsletter__form--not-submitted');
				const submittedContent = document.querySelector('.newsletter__form--submitted');

				if (!defaultContent || !submittedContent) {
					return;
				}

				defaultContent.classList.toggle('u-display-none');
				submittedContent.classList.toggle('u-display-none');
			});
		} catch (ex) {
			console.log('Error in sending form.', ex);
		}
	});
}

async function submitForm(token: string) {
	const form = document.getElementById('newsletter-form') as HTMLFormElement;

	if (!form) {
		return;
	}

	const inputs = form.querySelectorAll(`.${CLASS_NAME_NEWSLETTER_INPUT}[name]`);

	const query: string[] = [];

	inputs.forEach((input) => {
		if (input instanceof HTMLInputElement) {
			query.push(`${encodeURIComponent(input.name)}=${encodeURIComponent(input.value)}`);
		}
	});

	query.push(`token=${token}`);

	await fetch(
		`${'https://www.habaneroconsulting.com/_services/newsletter/subscribe'}?${query.join('&')}`
	);
}

newsletterFormInit();
