/**
 * Initialize - listen for window scroll event, fire fixNav function
 */
function primaryNavInit() {
	window.addEventListener('scroll', () => fixNav());
}

/**
 * Toggle fixed nav when scrolled 16px (1rem)
 */
function fixNav() {
	const nav = document.querySelector('.header');

	if (!nav) {
		return;
	}

	const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;

	if (scrollTop >= 16) {
		nav.classList.add('header--sticky');
	} else {
		nav.className = 'header';
	}
}

primaryNavInit();
